import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../layout";
import UserInfo from "../components/UserInfo";
import PostTags from "../components/PostTags";
import SEO from "../components/SEO";
import config from "../../blogposts/data/SiteConfig";
import { formatDate } from "../utils/global";
import pratap from "../../blogposts/images/profile-small.jpg";
import SocialLinks from "../components/SocialLinks";
import Video from "../components/Video";
import ViewCounter from "../components/ViewCounter";
import Suggested from "../components/Suggested";
import CommentBox from "../components/Comment";

export default function PostTemplate({ data, pageContext }) {
  const { slug, previous, next } = pageContext;
  const postNode = data.markdownRemark;
  const post = postNode.frontmatter;

  let thumbnail;

  if (!post.id) {
    post.id = slug;
  }

  if (!post.category_id) {
    post.category_id = config.postDefaultCategoryID;
  }

  if (post.thumbnail) {
    thumbnail = post.thumbnail.childImageSharp.fixed;
  }

  const date = formatDate(post.date);

  return (
    <Layout>
      <Helmet>
        <title>{`${post.title} – ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postPath={slug} postNode={postNode} postSEO />
      <div
        className="notification fadeInUp"
        style={{
          animationDelay: "1s",
        }}
      >
        <p>
          <a
            href="https://www.udemy.com/course/building-rest-apis-with-flask-and-python/"
            target="_blank"
            rel="noreferrer"
          >
            Checkout the latest course:{" "}
            <strong>Building REST APIs with Flask and Python in 2023</strong>
          </a>
        </p>
      </div>
      <article className="single container">
        <header className={`single-header ${!thumbnail ? "no-thumbnail" : ""}`}>
          {thumbnail && <Img fixed={post.thumbnail.childImageSharp.fixed} />}
          <div className="flex">
            <h1>{post.title}</h1>
            <div className="post-meta">
              <a
                href={`${post.authorUrl ? post.authorUrl : config.siteUrl}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={post.authorPicture ? post.authorPicture : pratap}
                  className="avatar-small"
                  alt="Article Author"
                />
              </a>
              <div>
                <a
                  className="post-author"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${post.authorUrl ? post.authorUrl : config.siteUrl}`}
                >
                  {post.author ? post.author : config.siteTitle}
                </a>
                <time className="date">{date}</time>
                <span>
                  <ViewCounter id={slug} />
                </span>
                <span style={{ marginLeft: 4 }}>
                  {`${
                    postNode.timeToRead > 4 ? postNode.timeToRead : 4
                  } mins read`}{" "}
                </span>
              </div>
            </div>
            <PostTags tags={post.tags} />
          </div>
        </header>
        {post.videoSourceURL && (
          <Video videoSrcURL={post.videoSourceURL} videoTitle={post.title} />
        )}
        <div
          className="post"
          dangerouslySetInnerHTML={{ __html: postNode.html }}
        />
        <SocialLinks postPath={slug} postNode={postNode} />
        <CommentBox />
        <Suggested previous={previous} next={next} />
      </article>
      <UserInfo config={config} />
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        thumbnail {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        slug
        date
        categories
        tags
        template
        author
        authorUrl
        authorPicture
        videoSourceURL
      }
      fields {
        slug
        date
      }
    }
  }
`;
